// extracted by mini-css-extract-plugin
export var softBlueGradient = "MegaFooter-module--soft-blue-gradient--2OrA2";
export var MegaFooter = "MegaFooter-module--MegaFooter--z_nl_";
export var contact = "MegaFooter-module--contact--V_Ezw";
export var contactBox = "MegaFooter-module--contactBox--29nEb";
export var info = "MegaFooter-module--info--2p-De";
export var inputGroup = "MegaFooter-module--inputGroup--SIpNk";
export var contactItem = "MegaFooter-module--contactItem--3jd5E";
export var contactIcon = "MegaFooter-module--contactIcon--19KaD";
export var workWithUs = "MegaFooter-module--workWithUs--1JBp1";
export var officesBox = "MegaFooter-module--officesBox--iXQra";
export var title = "MegaFooter-module--title--3mKwD";
export var offices = "MegaFooter-module--offices--1r_VP";
export var list = "MegaFooter-module--list--3xny9";
export var simpleFooter = "MegaFooter-module--simpleFooter--NgQ6d";
export var logoFooter = "MegaFooter-module--logoFooter--2YguK";
export var socialIcon = "MegaFooter-module--socialIcon--3htqB";
export var onlyLink = "MegaFooter-module--onlyLink--3vw5W";