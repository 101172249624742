export interface megaMenuType {
  title: string
  subtitle: string
  link?: string
  sameOrigin: boolean
  itens: megaMenuItensType[]
}

export interface megaMenuItensType {
  name: string
  link: string
  commingSoon?: boolean
  sameOrigin?: boolean
}

export const MenuItems = [
  {
    text: `Quem somos`,
    sameOrigin: true,
    href: `/#quem-somos`,
  },
  {
    text: `Cases de Sucesso`,
    sameOrigin: true,
    href: `/#cases-sucesso`,
  },
  {
    text: `Blog`,
    sameOrigin: false,
    href: `https://blog.valoremelhoria.com.br/`,
  },
  {
    text: `Entre em Contato`,
    sameOrigin: true,
    href: `/#contato`,
  },
]

export const SubMenuItens: megaMenuType[] = [
  {
    title: 'BPM',
    subtitle: 'Gestāo por Processos de Negócio',
    link: '/solucoes/gestao-por-processos-de-negocio-bpm',
    sameOrigin: true,
    itens: [
      {
        name: 'Treinamento BPM',
        link: '/solucoes/gestao-por-processos-de-negocio-bpm/treinamento-bpm/',
        sameOrigin: true,
        commingSoon: false,
      },
      {
        name: 'Aderência de Sistemas',
        link: '/solucoes/gestao-por-processos-de-negocio-bpm/aderencia-de-sistemas/',
        sameOrigin: true,
        commingSoon: false,
      },
      {
        name: 'Mapeamento e Melhoria de Processos',
        link: '/solucoes/gestao-por-processos-de-negocio-bpm/mapeamento-e-melhoria-de-processos/',
        sameOrigin: true,
        commingSoon: false,
      },
      {
        name: 'Escritório de Processos',
        link: '/solucoes/gestao-por-processos-de-negocio-bpm/escritorio-de-processos/',
        sameOrigin: true,
        commingSoon: false,
      },
    ],
  },
  {
    title: 'RPA',
    subtitle: 'Automaçāo Robótica de Processos',
    sameOrigin: false,
    itens: [
      {
        name: 'Roberty Automation',
        link: 'https://roberty.app',
      },
      {
        name: 'Como um robô pode ser um diferencial para sua empresa',
        link: 'https://blog.valoremelhoria.com.br/2021-05-04-como-um-rob%C3%B4-pode-ser-um-diferencial-para-sua-empresa/',
      },
    ],
  },
  {
    title: 'Controladoria',
    subtitle: 'Estruturaçāo do Processo de Controladoria',
    link: '/solucoes/controladoria',
    sameOrigin: true,
    itens: [
      {
        name: 'Gestão por Indicadores',
        link: '/solucoes/controladoria/gestao-por-indicadores-kpis',
        commingSoon: false,
        sameOrigin: true,
      },
      {
        name: 'Apuração de Custos',
        link: '/solucoes/controladoria/apuracao-de-custos',
        commingSoon: false,
        sameOrigin: true,
      },
      {
        name: 'Formação do Preço de Venda',
        link: '/solucoes/controladoria/formacao-do-preco-de-venda',
        commingSoon: false,
        sameOrigin: true,
      },
      {
        name: 'Estudos de Viabilidade Financeira',
        link: '/solucoes/controladoria/estudos-viabilidade-financeira',
        commingSoon: false,
        sameOrigin: true,
      },
    ],
  },
  {
    title: 'SGI',
    subtitle: 'Sistema de Gestão Integrado',
    link: '/solucoes/sistema-de-gestao-integrada',
    sameOrigin: true,
    itens: [
      // {
      //   name: 'Diagnóstico de Aderência às Normas',
      //   link: '/solucoes/sistema-de-gestao-integrada/diagnostico-de-aderencia-as-normas',
      //   commingSoon: true,
      // },
      // {
      //   name: 'Auditoria Interna',
      //   link: '/solucoes/sistema-de-gestao-integrada/auditoria-interna',
      //   commingSoon: true,
      // },
      // {
      //   name: 'Implantação de Sistemas de Gestão',
      //   link: '/solucoes/sistema-de-gestao-integrada/implantacao-de-sistemas-de-gestao',
      //   commingSoon: true,
      // },
      // {
      //   name: 'Treinamentos',
      //   link: '#',
      //   commingSoon: true,
      // },
    ],
  },
  {
    title: 'OKR',
    subtitle: 'Implementaçāo de Gestāo Ágil - OKR',
    link: '/solucoes/okr-objetivos-e-resultados-chaves',
    sameOrigin: true,
    itens: [
      {
        name: 'Boas práticas para implantação de OKRs',
        link: 'https://blog.valoremelhoria.com.br/2021-09-20-boas-pr%C3%A1ticas-para-implanta%C3%A7%C3%A3o-de-okrs/',
        commingSoon: false,
      },
    ],
  },
  {
    title: 'Compliance',
    subtitle: 'Implementaçāo de Boas Práticas de Compliance',
    link: '/solucoes/compliance',
    sameOrigin: true,
    itens: [
      {
        name: 'Gestão de Riscos',
        link: 'https://blog.valoremelhoria.com.br/2021-05-05-gest%C3%A3o-de-riscos/',
      },
      {
        name: 'ESG',
        link: 'https://blog.valoremelhoria.com.br/2021-05-29-esg-environmental-social-and-governance-e-sua-import%C3%A2ncia-para-atrair-novos-investidores/',
      },
      // {
      //   name: 'Anticorrupção e Antissuborno',
      //   link: '/solucoes/compliance/anticorrupcao-e-antissuborno',
      //   commingSoon: true,
      // },
      // {
      //   name: 'Gestão de Compliance',
      //   link: '/solucoes/compliance/gestao-de-compliance',
      //   commingSoon: true,
      // },
    ],
  },
]
