// extracted by mini-css-extract-plugin
export var softBlueGradient = "Header-module--soft-blue-gradient--2Yu3o";
export var header = "Header-module--header--28uoL";
export var headerSticky = "Header-module--headerSticky--1ts8-";
export var logoWhite = "Header-module--logoWhite--1KODw";
export var logo = "Header-module--logo--1kkRW";
export var menuItem = "Header-module--menuItem--1-EFJ";
export var isHover = "Header-module--isHover--3t9QU";
export var dropdonwIcon = "Header-module--dropdonwIcon--8TkuL";
export var megaMenu = "Header-module--megaMenu--6gMhQ";
export var open = "Header-module--open--w3z-f";
export var backdrop = "Header-module--backdrop--XLfVx";
export var content = "Header-module--content--ctJSe";
export var close = "Header-module--close--1yxVG";
export var title = "Header-module--title--blR1c";
export var itens = "Header-module--itens--101mk";
export var liItem = "Header-module--liItem--2YGYX";
export var active = "Header-module--active--TVthP";
export var subList = "Header-module--subList--tj3TM";
export var commingSoon = "Header-module--commingSoon--3hm2p";
export var expandMenu = "Header-module--expandMenu--wworx";
export var itemGrid = "Header-module--itemGrid--1BJEH";
export var itemTitle = "Header-module--itemTitle--2LFI6";