import React, { useCallback } from 'react'
import * as styles from '../styles/components/MegaFooter.module.scss'
import clsx from 'clsx'
import axios from 'axios'
import { useBoolean, useInput } from 'react-hanger'
import { SectionTitle } from './SectionTitle'
import { Divider } from './Divider'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebook,
  faInstagram,
  faLinkedinIn,
  faYoutube,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons'
import {
  faUser,
  faEnvelope,
  faPhoneAlt,
  faPhone,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons'
import logo from '../images/valore-logo-white.png'
import { Button } from './Button'
import OfficesList from '../components/OfficesList'
import toast from 'react-hot-toast'

const socials = [
  {
    href: `https://br.linkedin.com/company/valore-brasil---controladoria-de-resultados`,
    icon: faLinkedinIn,
  },
  {
    href: `https://pt-br.facebook.com/ValoreBrasil`,
    icon: faFacebook,
  },
  {
    href: `https://www.instagram.com/valorebrasil`,
    icon: faInstagram,
  },
  {
    href: `https://www.youtube.com/channel/UC9QDps1MGtw_M_7UUeVpwqg`,
    icon: faYoutube,
  },
  {
    href: `/politica-de-privacidade/`,
    onlyLink: true,
    text: `Política de Privacidade`,
  },
]

const contacts = [
  {
    href: `mailto:melhoria@valorebrasil.com.br`,
    text: <>melhoria@valorebrasil.com.br</>,
    icon: faEnvelope,
  },
  {
    href: `https://api.whatsapp.com/send?phone=5516993982007`,
    text: `(16) 99398-2007`,
    icon: faWhatsapp,
  },
  {
    href: `tel:+08007424210`,
    text: `0800 742 4210`,
    icon: faPhone,
  },
]

const contactFormUrl = `https://api.roberty.app/prod/1/website/createBitrixLead`

interface MegaFooterProps {
  isSimpleFooter?: boolean
  location: string
}

export const MegaFooter: React.FC<MegaFooterProps> = ({
  isSimpleFooter,
  location,
}) => {
  const name = useInput(``)
  const phone = useInput(``)
  const email = useInput(``)
  const message = useInput(``)
  const loading = useBoolean(false)

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      loading.setTrue()
      try {
        const formTitle =
          location === `/`
            ? `[Valore Melhoria] - ${name.value}`
            : `[Valore Melhoria - ${location}]`

        await axios.post(contactFormUrl, {
          title: formTitle,
          name: name.value,
          phone: phone.value,
          email: email.value,
          description: message.value,
        })
        name.clear()
        phone.clear()
        email.clear()
        message.clear()

        toast.success(
          `Sua mensagem foi enviada com sucesso!
          Aguarde, em breve entraremos em contato com você.`
        )
      } catch (error) {
        console.error(error)
        toast.error(
          'Houve um erro ao enviar sua mensagem. Por favor, tente novamente mais tarde.'
        )
      } finally {
        loading.setFalse()
      }
    },
    [name.value, phone.value, email.value, message.value]
  )

  return (
    <>
      <section className={styles.MegaFooter} id="contato">
        <div className={clsx(['container-fluid', styles.contact])}>
          <div className={clsx(['container py-5', styles.contactBox])}>
            <div className="row d-flex justify-content-center pb-3">
              <div className="col-12">
                <SectionTitle title="Vamos conversar?" className="no-padding" />
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-12 col-md-8">
                <p className={styles.info}>
                  Dê o primeiro passo em direção à transformação dos seus
                  resultados.
                  <br />
                  Entre em contato conosco preenchendo o formulário a seguir e
                  saiba como podemos te ajudar a alcançar os objetivos da sua
                  organização.
                </p>
              </div>
              <div className="col-12 col-md-6">
                <form
                  id="contatoForm"
                  onSubmitCapture={handleSubmit}
                  autoComplete="off"
                  role="presentation"
                >
                  <div className={clsx(styles.inputGroup)}>
                    <span>
                      <FontAwesomeIcon icon={faUser} size="1x" />
                    </span>
                    <input
                      name="name"
                      type="text"
                      placeholder="Seu nome"
                      required={true}
                      value={name.value}
                      onChange={name.onChange}
                      disabled={loading.value}
                    />
                  </div>

                  <div className={clsx(styles.inputGroup)}>
                    <span>
                      <FontAwesomeIcon icon={faPhoneAlt} size="1x" />
                    </span>
                    <input
                      name="phone"
                      type="tel"
                      placeholder="Telefone (com WhatsApp)"
                      required={true}
                      value={phone.value}
                      onChange={phone.onChange}
                      disabled={loading.value}
                    />
                  </div>

                  <div className={clsx(styles.inputGroup)}>
                    <span>
                      <FontAwesomeIcon icon={faEnvelope} size="1x" />
                    </span>
                    <input
                      name="email"
                      type="email"
                      placeholder="Seu melhor e-mail"
                      value={email.value}
                      onChange={email.onChange}
                      disabled={loading.value}
                    />
                  </div>

                  <div className={clsx(styles.inputGroup)}>
                    <textarea
                      name="message"
                      placeholder="Como podemos ajudar?"
                      required={true}
                      value={message.value}
                      onChange={message.onChange}
                      disabled={loading.value}
                    />
                  </div>

                  <Button
                    type="submit"
                    className={[loading.value && 'loading'].join(' ')}
                  >
                    {loading.value ? (
                      <>
                        Enviando
                        <FontAwesomeIcon
                          className="ml-3 fa-spin"
                          icon={faSpinner}
                          size="1x"
                        />
                      </>
                    ) : (
                      <>
                        Enviar
                        <FontAwesomeIcon
                          className="ml-3"
                          icon={faPaperPlane}
                          size="1x"
                        />
                      </>
                    )}
                  </Button>
                </form>
              </div>
              <div className="col-12 col-md-8 py-3">
                <Divider />
              </div>
              <div className="col-12 col-md-7 py-4">
                <div className="row">
                  {contacts.map((contact, index) => (
                    <div key={index} className="col-md mb-3 mb-lg-0">
                      <a
                        className={styles.contactItem}
                        href={contact.href}
                        rel="noopener"
                        target={
                          contact.href.indexOf(`http`) !== -1 ? `_blank` : ''
                        }
                      >
                        <div className={styles.contactIcon}>
                          <FontAwesomeIcon icon={contact.icon} size="lg" />
                        </div>
                        <span>{contact.text}</span>
                      </a>
                    </div>
                  ))}
                </div>
              </div>

              {!isSimpleFooter && (
                <div className="col-12 col-md-9 py-sm-4">
                  <div className={clsx(['col-auto', styles.workWithUs])}>
                    <h3>Trabalhe Conosco</h3>
                    <p>
                      Envie seu currículo através do e-mail{` `}
                      <a
                        href={`mailto:vaga@valorebrasil.com.br`}
                        rel="noopener"
                      >
                        vaga@valorebrasil.com.br
                      </a>
                    </p>
                    <p>
                      No título do e-mail insira seu{' '}
                      <strong>
                        nome completo e cargo pretendido e/ou áreas de interesse
                      </strong>
                      . Compartilhe também seu perfil no{' '}
                      <strong>LinkedIn</strong> para conhecermos um pouco mais
                      sobre você.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          {!isSimpleFooter && (
            <div className={clsx(['container', styles.officesBox])}>
              <div className="row">
                <div className={clsx(['col-md', styles.title])}>
                  <h3>Onde Estamos</h3>
                  <p>
                    Sempre um escritório perto de você para melhor lhe atender
                  </p>
                </div>
                <div className={clsx(['col-md-12', styles.offices])}>
                  <div className={styles.list}>
                    <OfficesList />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      <footer className={clsx({ [styles.simpleFooter]: isSimpleFooter })}>
        <div className="container">
          {!isSimpleFooter && (
            <Divider className="mt-5 mb-4 isFooter hidden-mobile" />
          )}

          <div className="row align-items-md-end">
            <div className="col mb-4 mb-sm-0 d-flex justify-content-center justify-content-sm-start align-items-sm-end">
              <img
                src={logo}
                alt="Valore Melhoria"
                className={styles.logoFooter}
              />
            </div>
            <div className="col-md-auto d-flex flex-column align-items-center align-items-md-end">
              <small>
                &copy; 2021 | Valore Brasil - Todos os direitos reservados.
              </small>
              <div className="d-flex mt-2">
                {socials.map((social, index) =>
                  social.onlyLink && social.text ? (
                    <>
                      <AnchorLink
                        key={index}
                        className={clsx([
                          `ml-3`,
                          styles.socialIcon,
                          { [styles.onlyLink]: social.onlyLink },
                        ])}
                        to={social.href}
                      >
                        <span>{social.text}</span>
                      </AnchorLink>
                    </>
                  ) : (
                    <>
                      <a
                        key={index}
                        className={clsx([
                          `ml-3`,
                          styles.socialIcon,
                          { [styles.onlyLink]: social.onlyLink },
                        ])}
                        href={social.href}
                        target="_blank"
                        rel="noopener"
                      >
                        {social.icon && <FontAwesomeIcon icon={social.icon} />}
                      </a>
                    </>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}
