import clsx from 'clsx'
import React from 'react'

import favicon from '../images/favicon.png'
import whiteFavicon from '../images/favicon-white.png'
import * as styles from '../styles/components/SectionTitle.module.scss'

interface SectionTitleProps {
  className?: string
  title: string
  description?: string
  whiteFavicon?: boolean
}

export const SectionTitle: React.FC<SectionTitleProps> = (props) => {
  return (
    <div
      className={clsx([
        props.className,
        `py-5 d-flex flex-column align-items-md-center`,
        styles.sectionTitle,
      ])}
    >
      <img
        src={props.whiteFavicon ? whiteFavicon : favicon}
        alt="Valore Brasil"
      />
      <h1>{props.title}</h1>
      <p>{props.description}</p>
    </div>
  )
}
