import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { navigate, Link } from 'gatsby'
import { debounce } from 'lodash'

import * as styles from '../styles/components/Header.module.scss'
import logo from '../images/valore-logo.png'
import logoWhite from '../images/valore-logo-white.png'
import { Button } from './Button'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useMediaQuery } from '../utils/hooks'

import { SubMenuItens, MenuItems, megaMenuItensType } from '../utils/MenuItens'
interface IHeaderProps {
  mainFolder?: string
  type: `megaMenu` | `default`
}

export const Header: React.FC<IHeaderProps> = ({ mainFolder, type }) => {
  const [sticky, setSticky] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)
  const [openMenuSubItem, setopenMenuSubItem] = useState({
    index: -1,
    open: false,
  })
  const isMobile = useMediaQuery(`580`)

  const onScroll = () => {
    setSticky(window.scrollY > 0)
    if (window.scrollY > 300) setOpenMenu(false)
  }

  useEffect(() => {
    window.addEventListener(`scroll`, onScroll)

    return () => {
      window.removeEventListener(`scroll`, onScroll)
    }
  }, [])

  const onMouseEnterDebounce = debounce(() => setOpenMenu(true), 300)

  const handlOnMouseLeave = () => {
    onMouseEnterDebounce.cancel()
  }

  const renderMenuItem = (
    href: string,
    text: string,
    isSubItem: boolean,
    commingSoon?: boolean,
    sameOrigin?: boolean
  ) => {
    if (!isSubItem) {
      return <Link to={href}>{text}</Link>
    }

    if (sameOrigin) {
      return !commingSoon ? (
        <Link to={href}>{text}</Link>
      ) : (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault()
          }}
        >
          {text}
        </a>
      )
    } else {
      return !commingSoon ? (
        <a href={href} target="_blank" rel="noopener">
          {text}
        </a>
      ) : (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault()
          }}
        >
          {text}
        </a>
      )
    }
  }

  return (
    <header
      className={clsx({
        [styles.header]: true,
        [styles.headerSticky]: sticky,
      })}
    >
      <div className="container">
        <div
          className={clsx([
            `row`,
            `justify-content-between`,
            `align-items-center`,
          ])}
        >
          <div className="col-auto">
            <AnchorLink to="/">
              <img className={styles.logo} src={logo} alt="Valore Brasil" />
              <img
                className={styles.logoWhite}
                src={logoWhite}
                alt="Valore Brasil"
              />
            </AnchorLink>
          </div>

          <div className="col-auto">
            <nav className={clsx([`main-menu`])}>
              <a
                className={clsx([
                  styles.menuItem,
                  `d-lg-inline-block`,
                  openMenu && styles.isHover,
                ])}
                onClick={(e) => {
                  e.preventDefault()
                  setOpenMenu(true)
                }}
                onMouseEnter={onMouseEnterDebounce}
                onMouseLeave={handlOnMouseLeave}
              >
                Soluções
                <FontAwesomeIcon
                  className={styles.dropdonwIcon}
                  icon={faChevronDown}
                />
              </a>
              {MenuItems.map((item, index) =>
                item.sameOrigin ? (
                  <AnchorLink
                    key={index}
                    className={clsx([
                      styles.menuItem,
                      `d-none`,
                      `d-lg-inline-block`,
                    ])}
                    to={
                      !mainFolder
                        ? item.href
                        : `${mainFolder}/${item.href.split('/')[1]}`
                    }
                  >
                    {item.text}
                  </AnchorLink>
                ) : (
                  <a
                    key={index}
                    className={clsx([
                      styles.menuItem,
                      `d-none`,
                      `d-lg-inline-block`,
                    ])}
                    href={item.href}
                    target="_blank"
                    rel="noopener"
                  >
                    {item.text}
                  </a>
                )
              )}
            </nav>
          </div>
        </div>
        {type === `megaMenu` && (
          <div
            className={clsx([
              styles.megaMenu,
              styles.expandMenu,
              openMenu && styles.open,
            ])}
          >
            <div
              className={styles.backdrop}
              onClick={() => {
                setOpenMenu(false)
                setopenMenuSubItem({ index: -1, open: false })
              }}
            ></div>
            <div
              className={styles.content}
              onMouseLeave={() => {
                setTimeout(() => {
                  setOpenMenu(false)
                  setopenMenuSubItem({ index: -1, open: false })
                }, 300)
              }}
            >
              {isMobile && (
                <div
                  className={styles.close}
                  onClick={() => {
                    setOpenMenu(false)
                    setopenMenuSubItem({ index: -1, open: false })
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              )}

              <div className={styles.title}>
                <div className="row align-items-center">
                  <div className="col">
                    <h1>Nossas Soluções</h1>
                    <p>
                      Dê o primeiro passo em direção à transformação. Fornecemos
                      soluções personalizadas, entendendo as dores do seu
                      negócio. Saiba como alavancar os seus resultados.
                    </p>
                  </div>
                  <div className="col-auto">
                    <AnchorLink
                      to={!mainFolder ? '#contato' : `${mainFolder}/#contato`}
                    >
                      <Button>Fale com um especialista</Button>
                    </AnchorLink>
                  </div>
                </div>
              </div>

              <div className={styles.itens}>
                {SubMenuItens.map((item, index) => (
                  <div key={index} className={styles.itemGrid}>
                    <div className={styles.itemTitle}>
                      <h3>{item.title}</h3>
                      <span>
                        {item.link ? (
                          renderMenuItem(
                            item.link,
                            item.subtitle,
                            false,
                            item.sameOrigin
                          )
                        ) : (
                          <>{item.subtitle}</>
                        )}
                      </span>
                    </div>
                    <ul>
                      {item.itens.map((subItem, subItemKey) => (
                        <li
                          key={subItemKey}
                          className={clsx({
                            [styles.commingSoon]: subItem.commingSoon,
                          })}
                        >
                          {renderMenuItem(
                            subItem.link,
                            subItem.name,
                            true,
                            subItem.commingSoon,
                            subItem.sameOrigin
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {type === `default` && (
          <div className={clsx([styles.megaMenu, openMenu && styles.open])}>
            <div
              className={styles.backdrop}
              onClick={() => {
                setOpenMenu(false)
                setopenMenuSubItem({ index: -1, open: false })
              }}
            ></div>
            <div
              className={styles.content}
              onMouseLeave={() => {
                setTimeout(() => {
                  setOpenMenu(false)
                  setopenMenuSubItem({ index: -1, open: false })
                }, 300)
              }}
            >
              {isMobile && (
                <div
                  className={styles.close}
                  onClick={() => {
                    setOpenMenu(false)
                    setopenMenuSubItem({ index: -1, open: false })
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              )}

              <div className={styles.title}>
                <h1>Nossas Soluções</h1>
                <p>
                  Dê o primeiro passo em direção à transformação. Fornecemos
                  soluções personalizadas, entendendo as dores do seu negócio.
                  Saiba como alavancar os seus resultados.
                </p>
                <AnchorLink
                  to={!mainFolder ? '#contato' : `${mainFolder}/#contato`}
                >
                  <Button>Fale com um especialista</Button>
                </AnchorLink>
              </div>
              <div className={styles.itens}>
                <ul>
                  {SubMenuItens.map((item, index) => (
                    <>
                      <li
                        key={index}
                        className={clsx([
                          styles.liItem,
                          openMenuSubItem.index === index && styles.active,
                        ])}
                        onClick={() => {
                          if (isMobile) {
                            setopenMenuSubItem({
                              index:
                                openMenuSubItem.index === index ? -1 : index,
                              open: true,
                            })
                          }
                        }}
                        onMouseEnter={() => {
                          if (!isMobile) {
                            setopenMenuSubItem({
                              index: index,
                              open: true,
                            })
                          }
                        }}
                      >
                        <span
                          className={
                            openMenuSubItem.index === index &&
                            openMenuSubItem.open &&
                            styles.active
                          }
                          onClick={() => {
                            if (!isMobile) {
                              if (item.link && item.sameOrigin)
                                navigate(item.link)
                            }
                          }}
                        >
                          {item.title}
                          <FontAwesomeIcon
                            className={styles.dropdonwIcon}
                            icon={faChevronDown}
                          />
                        </span>
                        {openMenuSubItem.index === index &&
                          openMenuSubItem.open && (
                            <ul className={clsx([styles.subList])}>
                              <h3>
                                {item.link ? (
                                  renderMenuItem(
                                    item.link,
                                    item.subtitle,
                                    false,
                                    item.sameOrigin
                                  )
                                ) : (
                                  <>{item.subtitle}</>
                                )}
                              </h3>
                              {item.itens.map(
                                (subItem: megaMenuItensType, index) => (
                                  <li
                                    key={index}
                                    className={clsx({
                                      [styles.commingSoon]: subItem.commingSoon,
                                    })}
                                  >
                                    {renderMenuItem(
                                      subItem.link,
                                      subItem.name,
                                      true,
                                      subItem.commingSoon,
                                      subItem.sameOrigin
                                    )}
                                  </li>
                                )
                              )}
                            </ul>
                          )}
                      </li>
                    </>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </header>
  )
}
